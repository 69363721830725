import {
  Box,
  Checkbox,
  CheckboxProps,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
  withStyles,
} from "@material-ui/core";
import { AreaConfig, CurveMode, HeadingMode, Role, UnitSystem } from "biohub-model";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../store/reducers/systemReducer";
import CompleteNumberEditor, { ColorScheme, GridScheme } from "../CompleteNumberEditor/default";
import UnitConversionHelper from "../../../../core/helper/UnitConversionHelper";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

interface Props {
  areaConfig: AreaConfig;
  // To use in the profile settings
  unitSystem?: UnitSystem;
  /**
   * This will be called with an already converted area config every time. It won't
   * be called when invalid text is inserted in the textboxes. The values are always
   * in metric, even when the user inserts values in imperial units.
   */
  onChange: (propName: keyof AreaConfig, value: any) => void;
  /** Optional style to pass to each grid item. */
  gridStyle?: React.CSSProperties;
  children?: React.ReactNode;
  childrenPosition?: "ending";
  colorScheme: ColorScheme;
  gridScheme: GridScheme;

  disableEdition?: Array<keyof AreaConfig>;

  validateValue: (valid: boolean) => void;
  usingOnlineElevation: boolean;
}

const CheckBox = (color: string) =>
  withStyles({
    root: {
      color: color,
      "&$checked": {
        color: color,
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

/**
 * Displays a list of text inputs for editing an area config.
 *
 * This component automatically handles unit conversion.
 *
 * Anything passed as children to this component will render between the top two and bottom
 * two fields. This exists because of a requirement that some other unrelated fields need to
 * render between those fields in the project/area creation modal.
 */
export default (props: Props) => {
  // Each of these fields is stored in a certain unit in the config object, that never
  // changes. We need to store an internal copy of the config that's passed as props
  // in order to use strings that correspond to what's displayed on screen.
  // But even before that, we need to know what unit system is currently configured.
  const currentProfile = useSelector((state: SystemState) => state.profile.userProfile!);
  const _unitSystem: UnitSystem = useSelector((state: SystemState) => {
    if (state.profile.userProfile !== null && state.profile.userProfile.role !== Role.external) {
      return state.profile.userProfile.preferences.unitSystem;
    }
    return UnitSystem.metric;
  });

  const unitSystem = props.unitSystem ?? _unitSystem;

  const speedConversionFactor = UnitConversionHelper.speedConversion(unitSystem);
  const distanceConversionFactor = UnitConversionHelper.distanceConversion(unitSystem);

  const intl = useIntl();

  const [isValidFlightSpeed, setIsValidFlightSpeed] = useState<boolean>(false);
  const [isValidTrackWidth, setIsValidTrackWidth] = useState<boolean>(false);
  const [isValidAreaPadding, setIsValidAreaPadding] = useState<boolean>(false);
  const [isValidFlightHeight, setIsValidFlightHeight] = useState<boolean>(false);
  const [isValidCurvePercentage, setIsValidCurvePercentage] = useState<boolean>(true);

  useEffect(() => {
    props.validateValue(
      isValidFlightSpeed &&
        isValidTrackWidth &&
        isValidAreaPadding &&
        isValidFlightHeight &&
        isValidCurvePercentage
    );
  }, [
    isValidFlightSpeed,
    isValidTrackWidth,
    isValidAreaPadding,
    isValidFlightHeight,
    isValidCurvePercentage,
  ]);

  const _areaCurveModeLocalized: string = intl.formatMessage({ id: "map.newArea.curveMode" });
  const _areaCurveModeCurvedLocalized: string = intl.formatMessage({
    id: "map.newArea.curveMode.curved",
  });
  const _areaCurveModeNormalLocalized: string = intl.formatMessage({
    id: "map.newArea.curveMode.normal",
  });
  const _areaHeadingModeLocalized: string = intl.formatMessage({ id: "map.newArea.headingMode" });
  const _areaHeadingModeNextWaypointLocalized: string = intl.formatMessage({
    id: "map.newArea.headingMode.nextWaypoint",
  });
  const _areaHeadingModeManualLocalized: string = intl.formatMessage({
    id: "map.newArea.headingMode.manual",
  });

  const baseColor = props.colorScheme == "default" ? "#EF7622" : "var(--primary)";
  const CheckBoxComponent = CheckBox(baseColor);

  return (
    <>
      <Grid item lg={12} xs={12} style={props.gridStyle}>
        {/** Item 1: Flight speed. */}

        <CompleteNumberEditor
          initialValue={props.areaConfig.flightSpeed}
          unitText={unitSystem === UnitSystem.metric ? "km/h" : "miph"}
          label={intl.formatMessage({ id: "routePlanning.param.flightSpeed" })}
          legend={intl.formatMessage({ id: "routePlanning.param.flightSpeed.legend" })}
          minValue={0}
          maxValue={unitSystem === UnitSystem.metric ? 54 : 54 * 3.281}
          inComingConversionFactor={speedConversionFactor.incoming}
          outComingConversionFactor={speedConversionFactor.outgoing}
          onChange={(n) => props.onChange("flightSpeed", n)}
          validateValue={setIsValidFlightSpeed}
          disabled={
            props.disableEdition !== undefined && props.disableEdition.includes("flightSpeed")
          }
          colorScheme={props.colorScheme}
          gridScheme={props.gridScheme}
        />

        {/** Item 2: Track width. */}
        <CompleteNumberEditor
          initialValue={props.areaConfig.trackWidth}
          unitText={unitSystem === UnitSystem.metric ? "m" : "ft"}
          label={intl.formatMessage({ id: "routePlanning.param.trackWidth" })}
          minValue={5}
          maxValue={unitSystem === UnitSystem.metric ? 50 : 50 * 3.281}
          inComingConversionFactor={distanceConversionFactor.incoming}
          outComingConversionFactor={distanceConversionFactor.outgoing}
          onChange={(n) => props.onChange("trackWidth", n)}
          validateValue={setIsValidTrackWidth}
          disabled={
            props.disableEdition !== undefined && props.disableEdition.includes("trackWidth")
          }
          colorScheme={props.colorScheme}
          gridScheme={props.gridScheme}
        />

        {/** Item 3: Area indentation (padding). */}
        <CompleteNumberEditor
          initialValue={props.areaConfig.areaPadding}
          unitText={unitSystem === UnitSystem.metric ? "m" : "ft"}
          label={intl.formatMessage({ id: "routePlanning.param.areaPadding" })}
          minValue={0}
          maxValue={unitSystem === UnitSystem.metric ? 50 : 50 * 3.281}
          inComingConversionFactor={distanceConversionFactor.incoming}
          outComingConversionFactor={distanceConversionFactor.outgoing}
          onChange={(n) => props.onChange("areaPadding", n)}
          validateValue={setIsValidAreaPadding}
          disabled={
            props.disableEdition !== undefined && props.disableEdition.includes("areaPadding")
          }
          colorScheme={props.colorScheme}
          gridScheme={props.gridScheme}
        />

        {/** Item 3.5: When present, children render here. */}
        {props.childrenPosition !== "ending" && props.children}

        {/** Item 4: Flight height. */}
        <CompleteNumberEditor
          initialValue={props.areaConfig.flightHeight}
          unitText={unitSystem === UnitSystem.metric ? "m" : "ft"}
          label={intl.formatMessage({ id: "routePlanning.param.flightHeight" })}
          minValue={props.usingOnlineElevation ? 0.1 : -200}
          maxValue={unitSystem === UnitSystem.metric ? 120 : 120 * 3.281}
          inComingConversionFactor={distanceConversionFactor.incoming}
          outComingConversionFactor={distanceConversionFactor.outgoing}
          onChange={(n) => props.onChange("flightHeight", n)}
          validateValue={setIsValidFlightHeight}
          disabled={
            props.disableEdition !== undefined && props.disableEdition.includes("flightHeight")
          }
          colorScheme={props.colorScheme}
          gridScheme={props.gridScheme}
        />

        {props.childrenPosition === "ending" && props.children}

        {/*
         * Heading Mode and Curve Mode configurations
         */}

        <Selector<HeadingMode>
          label={_areaHeadingModeLocalized + ":"}
          value={props.areaConfig.headingMode}
          values={[HeadingMode.nextWaypoint, HeadingMode.manual]}
          getValueLabel={(value) => {
            if (value === HeadingMode.nextWaypoint) return _areaHeadingModeNextWaypointLocalized;
            else return _areaHeadingModeManualLocalized;
          }}
          selectValue={(value) => props.onChange("headingMode", value)}
          color={baseColor}
        />
        <Selector<CurveMode>
          label={_areaCurveModeLocalized + ":"}
          value={props.areaConfig.curveMode}
          values={[CurveMode.curved, CurveMode.normal]}
          getValueLabel={(value) => {
            if (value === CurveMode.curved) return _areaCurveModeCurvedLocalized;
            else return _areaCurveModeNormalLocalized;
          }}
          selectValue={(value) => props.onChange("curveMode", value)}
          color={baseColor}
        />

        {/** Curve Percentage */}
        {props.areaConfig.curveMode === CurveMode.curved && (
          <>
            <Grid xs={12}>
              <Box height={10} />
            </Grid>
            <CompleteNumberEditor
              initialValue={props.areaConfig.defaultCurvePercentage}
              unitText={"%"}
              label={intl.formatMessage({ id: "option.preferences.defaultCurvePercentage" })}
              minValue={0}
              maxValue={100}
              inComingConversionFactor={1}
              outComingConversionFactor={1}
              onChange={(n) => props.onChange("defaultCurvePercentage", n)}
              validateValue={setIsValidCurvePercentage}
              disabled={
                props.disableEdition !== undefined &&
                props.disableEdition.includes("defaultCurvePercentage")
              }
              colorScheme={props.colorScheme}
              gridScheme={props.gridScheme}
            />
          </>
        )}
        <FormControlLabel
          label={
            <Typography color="textPrimary">
              {intl.formatMessage({ id: "routePlanning.param.considerRelief" })}
            </Typography>
          }
          disabled={
            props.disableEdition !== undefined &&
            props.disableEdition.includes("mustConsiderRelief")
          }
          color="primary"
          className={"switch-label"}
          control={
            <CheckBoxComponent
              checked={props.areaConfig.mustConsiderRelief}
              onChange={(_) => {
                props.onChange("mustConsiderRelief", !props.areaConfig.mustConsiderRelief);
              }}
            />
          }
        />
        <FormControlLabel
          label={
            <Typography color="textPrimary">
              {intl.formatMessage({
                id: "routePlanning.param.useHighestPolygonPointElevationAsHomePoint",
              })}
            </Typography>
          }
          disabled={
            props.disableEdition !== undefined &&
            props.disableEdition.includes("useHighestPolygonPointElevationDataAsHomePoint")
          }
          color="primary"
          className={"switch-label"}
          control={
            <CheckBoxComponent
              checked={props.areaConfig.useHighestPolygonPointElevationDataAsHomePoint}
              onChange={(_) => {
                props.onChange(
                  "useHighestPolygonPointElevationDataAsHomePoint",
                  !props.areaConfig.useHighestPolygonPointElevationDataAsHomePoint
                );
              }}
            />
          }
        />
        <FormControlLabel
          label={
            <Typography color="textPrimary">
              {intl.formatMessage({
                id: "routePlanning.param.canUseOutsidePolygonSegmentsInRoute",
              })}
            </Typography>
          }
          disabled={
            props.disableEdition !== undefined &&
            props.disableEdition.includes("canUseOutsidePolygonSegmentsInRoute")
          }
          color="primary"
          className={"switch-label"}
          control={
            <CheckBoxComponent
              checked={props.areaConfig.canUseOutsidePolygonSegmentsInRoute}
              onChange={(_) => {
                props.onChange(
                  "canUseOutsidePolygonSegmentsInRoute",
                  !props.areaConfig.canUseOutsidePolygonSegmentsInRoute
                );
              }}
            />
          }
        />
        <FormControlLabel
          label={
            <Typography color="textPrimary">
              {intl.formatMessage({ id: "routePlanning.param.useReleaseRateCorrectionFactor" })}
            </Typography>
          }
          control={
            <CheckBoxComponent
              checked={props.areaConfig.usingReleaseRateAreaCorrectionFactor}
              onChange={(_) => {
                props.onChange(
                  "usingReleaseRateAreaCorrectionFactor",
                  !props.areaConfig.usingReleaseRateAreaCorrectionFactor
                );
              }}
            />
          }
        />
      </Grid>
    </>
  );
};

function Selector<T>(props: {
  label: string;
  value: T;
  values: Array<T>;
  getValueLabel: (value: T) => string;
  selectValue: (value: T) => void;
  disabled?: boolean;
  color: string;
}): JSX.Element {
  const classes = useStyles(props.color)();
  const ColoredSelectorButton = SelectorButton(props.color);
  const changeGroupSelectorChildren: Array<JSX.Element> = [];
  props.values.forEach((value, index) => {
    changeGroupSelectorChildren.push(
      <ColoredSelectorButton
        key={index}
        value={value}
        disabled={props.disabled}
        size="small"
        onClick={() => {
          props.selectValue(value);
        }}
      >
        {props.getValueLabel(value)}
      </ColoredSelectorButton>
    );
    if (props.values.length !== index + 1) {
      changeGroupSelectorChildren.push(
        <Divider key={props.label} flexItem orientation="vertical" className={classes.divider} />
      );
    }
  });

  const ColoredChangeGroupSelector = ChangeGroupSelector(props.color);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: "black",
      }}
    >
      {props.label}
      <ColoredChangeGroupSelector value={props.value} exclusive color="secondary">
        {changeGroupSelectorChildren}
      </ColoredChangeGroupSelector>
    </div>
  );
}

const useStyles = (color: string) =>
  makeStyles({
    divider: {
      backgroundColor: color,
    },
  });

const ChangeGroupSelector = (color: string) =>
  withStyles({
    root: {
      color: "white",
      margin: "3px",
      outline: "none",
      boxSizing: "border-box",
      cursor: "pointer",
      borderRadius: "5px",
      border: "1px solid " + color,
      background: "rgba(0,0,0,0)",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
    },
  })(ToggleButtonGroup);

const SelectorButton = (color: string) =>
  withStyles({
    root: {
      "&.Mui-disabled": { opacity: 0.7, color: "white" },
      "&$selected": {
        color: color,
        opacity: 1.0,
      },
      height: 25,
      color: "black",
    },
    selected: {},
  })(ToggleButton);
