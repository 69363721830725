/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { IntlShape, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Grid,
  Select,
  Button,
  Icon,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  FormControl,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import {
  AreaConfig,
  CurveMode,
  DirectClient,
  HeadingMode,
  Profile,
  Profile as ProfileModel,
  ProfilePreferences,
  Role,
  UnitSystem,
} from "biohub-model";
import { useDispatch, useSelector } from "react-redux";
import { ProfileState } from "../../../store/reducers/profileReducer";
import { SystemState } from "../../../store/reducers/systemReducer";
import { addProfile, removeProfile, updateProfile } from "../../../store/actions/profileActions";
import { v4 as uuid } from "uuid";
import UserTableComponent from "../../../components/Atomic/UserTable";

import { Modal } from "../styles";
import { searchZipCode } from "../../../services/SearchPlaces";
import { BiohubResponse } from "../../../services/axios/BiohubApi";
import {
  CountryResponse,
  ErrorsForm,
  getNumberCountry,
  isValidFormField,
} from "../../../services/FormValidatorsService";
import { Country, getCountries, loadLocales } from "../../../services/NationalityService";
import { BiohubLocale } from "../../../store/reducers/localeReducer";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import InsertPhone from "../../../components/Atomic/Inputs/Phone";
import DropdownOptions from "../../../components/Atomic/Inputs/DropdownOptions";
import { GoogleMapsSearchInfo } from "../../../services/Geocoder";

export const defaultProfilePreferences: Omit<ProfilePreferences, "id" | "areaPreferences"> = {
  coordinateDecimalPrecision: 5,
  languageCode: "EN",
  showBatteriesSeparately: true,
  useTwoFactorAuthentication: false,
  unitSystem: UnitSystem.imperial,
  useSexagesimalCoordinates: true,
  mustReleaseEntireArea: true,
};

export const defaultAreaPreferences: Omit<AreaConfig, "id"> = {
  areaPadding: 5,
  flightHeight: 10,
  flightSpeed: 15,
  mustConsiderRelief: true,
  trackWidth: 15,
  curveMode: CurveMode.curved,
  headingMode: HeadingMode.nextWaypoint,
  defaultCurvePercentage: 70.0,
  useHighestPolygonPointElevationDataAsHomePoint: true,
  canUseOutsidePolygonSegmentsInRoute: false,
  usingReleaseRateAreaCorrectionFactor: false,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  menuPaper: {
    maxHeight: 200,
    top: "200px !important",
  },
  menuPaper2: {
    maxHeight: 200,
    top: "250px !important",
  },
}));

function profileRoleToString(role: Role): string {
  switch (role) {
    case Role.master:
      return "Master";
    case Role.admin:
      return "Administrator";
    case Role.manager:
      return "Manager";
    case Role.operator:
      return "Operator";
    case Role.external:
      return "External";
    default:
      return "";
  }
}

function getRole(
  role: string
): Role.master | Role.admin | Role.manager | Role.operator | Role.external | null {
  if (role === "operator") return Role.operator;
  if (role === "manager") return Role.manager;
  if (role === "external") return Role.external;
  return null;
}

export default function UserTable() {
  const intl = useIntl();
  const classes = useStyles();
  const [locale, setLocale] = React.useState("pt");
  const [countriesNationality, setCountriesNationality] = React.useState<Country[]>([]);
  const [open, setOpen] = React.useState(false);

  const loadingProfiles: boolean = useSelector(
    (state: SystemState) => state.profile.loadingProfiles
  );

  const [editingProfile, editingProfileSetValue] = React.useState<Profile | undefined>();

  const viewProfile = (profile?: Profile, open?: boolean) => {
    loadCountries();
    if (profile !== undefined) {
      editingProfileSetValue(profile);
    }
    editingProfileSetValue(profile);
    if (profile !== undefined) {
      setOpen(true);
    }
    if (open !== undefined) {
      editingProfileSetValue(undefined);
      setOpen(open);
    }
  };

  const dispatch = useDispatch();

  const localeSystem: BiohubLocale = useSelector((state: SystemState) => state.locale.localeCode);

  const profileState: ProfileState = useSelector((state: SystemState) => state.profile);

  const userProfile: Profile | null = useSelector(
    (state: SystemState) => state.profile.userProfile
  );

  const _extractNumberInformations = (phone: string): CountryResponse => {
    return getNumberCountry(phone);
  };

  const directClient: DirectClient = profileState.directClient as DirectClient;
  const profiles: Array<ProfileModel> =
    profileState.directClient !== null ? profileState.directClient.profiles : [];

  const loadCountries = () => {
    loadLocales();
    if (localeSystem.length > 2) {
      setLocale(localeSystem.split("-")[0]);
    } else {
      setLocale(localeSystem);
    }
    setCountriesNationality(getCountries(locale));
  };

  const handleClickNewUser = () => {
    viewProfile(undefined, true);
    // editingProfileSetValue(undefined);
    // setOpen(true);
  };
  const handleCloseNewUser = () => {
    viewProfile(undefined, false);
    // setOpen(false);
    //setLanguageCode("");
  };

  const handleClickEditUser = (profile: Profile) => {
    viewProfile(profile);
    // editingProfileSetValue(profile);
    // setOpen(true);
  };
  const handleCloseEditUser = () => {
    viewProfile(undefined, false);
    // setOpen(false);
    // //setLanguageCode("");
    // editingProfileSetValue(undefined);
  };

  const handleSearchZip = async (cep: string) => {
    const result = await searchZipCode(cep);
    return result;
  };

  const handleAddUser = (form: ProfileFormState) => {
    if (profileState.directClient === null) {
      return;
    }

    const areaPreferences = { ...defaultAreaPreferences, id: uuid() };
    const profilePreferences: ProfilePreferences = {
      ...defaultProfilePreferences,
      id: uuid(),
      areaPreferences: areaPreferences,
    };
    const role = getRole(form.role);
    if (role === null) return;
    let newProfile: Profile;
    if (role !== Role.external) {
      newProfile = {
        isRegistrationCompleted: false,
        id: uuid(),
        role: role,
        name: form.name,
        profileImageUrl: "",
        nationality: form.nationality,
        email: form.email,
        cellphoneNumber: form.cellphoneNumber,
        landlineNumber:
          form.landlineNumber.length > 0 ? `+${form.landlineNumber}${form.landlineNumber}` : "",
        address: {
          id: uuid(),
          country: form.country,
          state: form.state,
          city: form.city,
          street: form.street,
          number: form.number,
          zipCode: form.zipCode,
        },
        rg: form.rg,
        document: form.document,
        directClientId: profileState.directClient.id,
        agricultureNumber: form.agricultureNumber,
        aviationNumber: form.aviationNumber,
        crea: form.enginnerNumber,
        preferences: profilePreferences,
      };
    } else {
      newProfile = {
        isRegistrationCompleted: false,
        id: uuid(),
        role: role,
        name: form.name,
        profileImageUrl: "",
        email: form.email,
        cellphoneNumber: form.cellphoneNumber,
        directClientId: directClient.id,
        indirectClientId: null,
      };
    }

    dispatch(addProfile(newProfile));
    handleCloseNewUser();
  };

  const handleEditUser = (form: ProfileFormState) => {
    if (profileState.directClient === null) {
      return;
    }

    const role: Role | null = getRole(form.role);

    const newPhone = form.cellphoneNumber;
    const newLandlineNumber = form.landlineNumber.length > 0 ? form.landlineNumber : "";

    if (role === null) return;
    let updatedProfile: { id: string } & Partial<Profile> & {
        role: Role.admin | Role.external | Role.manager | Role.master | Role.operator;
      };
    const previousProfile: Profile = editingProfile as Profile;
    if (role !== Role.external) {
      updatedProfile = {
        id: previousProfile.id,
        role: role,
        nationality: form.nationality,
        name: previousProfile.name !== form.name ? form.name : undefined,
        email: previousProfile.email !== form.email ? form.email : undefined,
        cellphoneNumber: previousProfile.cellphoneNumber !== newPhone ? newPhone : undefined,
        address:
          previousProfile.role === Role.external ||
          previousProfile.address.country !== form.country ||
          previousProfile.address.state !== form.state ||
          previousProfile.address.city !== form.city ||
          previousProfile.address.street !== form.street ||
          previousProfile.address.number !== form.number ||
          previousProfile.address.zipCode !== form.zipCode
            ? {
                id: previousProfile.role !== Role.external ? previousProfile.address.id : uuid(),
                country: form.country,
                state: form.state,
                city: form.city,
                street: form.street,
                number: form.number,
                zipCode: form.zipCode,
              }
            : undefined,
        rg:
          previousProfile.role === Role.external || previousProfile.rg !== form.rg
            ? form.rg
            : undefined,
        document:
          previousProfile.role === Role.external || previousProfile.document !== form.document
            ? form.document
            : undefined,
        directClientId: profileState.directClient.id,
        agricultureNumber:
          previousProfile.role === Role.external ||
          previousProfile.agricultureNumber !== form.agricultureNumber
            ? form.agricultureNumber
            : undefined,
        landlineNumber:
          previousProfile.role === Role.external ||
          previousProfile.landlineNumber !== newLandlineNumber
            ? newLandlineNumber
            : undefined,
        aviationNumber:
          previousProfile.role === Role.external ||
          previousProfile.aviationNumber !== form.aviationNumber
            ? form.aviationNumber
            : undefined,
        crea:
          previousProfile.role === Role.external || previousProfile.crea !== form.enginnerNumber
            ? form.enginnerNumber
            : undefined,
      };
    } else {
      updatedProfile = {
        id: previousProfile.id,
        role: role,
        name: previousProfile.name !== form.name ? form.name : undefined,
        email: previousProfile.email !== form.email ? form.email : undefined,
        cellphoneNumber: previousProfile.cellphoneNumber !== newPhone ? newPhone : undefined,
        directClientId: directClient.id,
        indirectClientId: null,
      };
    }

    dispatch(updateProfile(updatedProfile));
    handleCloseEditUser();
  };

  //terms
  const termUsers = intl.formatMessage({ id: "info.users" });
  const termAddNew = intl.formatMessage({ id: "action.add.new" });
  const termName = intl.formatMessage({ id: "placeholder.name" });
  const termType = intl.formatMessage({ id: "placeholder.type" });
  const termContact = intl.formatMessage({ id: "placeholder.contact" });
  const termRegistrationStatus = intl.formatMessage({ id: "profile.registrationStatus.name" });
  const termRegistrationStatusCompleted = intl.formatMessage({
    id: "profile.registrationStatus.completed",
  });
  const termRegistrationStatusPending = intl.formatMessage({
    id: "profile.registrationStatus.pending",
  });

  return (
    <div>
      {userProfile !== null &&
        userProfile.role <= Role.manager &&
        (!loadingProfiles ? (
          <UserTableComponent<Profile>
            items={profiles}
            titleTerm={termUsers}
            addTerm={
              userProfile !== null && userProfile.role !== Role.master ? termAddNew : undefined
            }
            onAddFunction={
              userProfile !== null && userProfile.role !== Role.master
                ? handleClickNewUser
                : undefined
            }
            classes={["id", termName, termType, termContact, termRegistrationStatus]}
            formatItems={(item: Profile) => {
              return [
                item.id,
                item.name,
                profileRoleToString(item.role),
                item.cellphoneNumber,
                item.isRegistrationCompleted
                  ? termRegistrationStatusCompleted
                  : termRegistrationStatusPending,
              ];
            }}
            getItemStyle={(item: Profile) => {
              return [
                undefined,
                undefined,
                undefined,
                undefined,
                item.isRegistrationCompleted ? undefined : { color: "var(--highlighted)" },
              ];
            }}
            onViewItem={(itemId: string) => {
              let selectedProfile: Profile | undefined = undefined;
              for (let i = 0; i < profiles.length && selectedProfile === undefined; i++) {
                if (profiles[i].id === itemId) {
                  selectedProfile = profiles[i];
                }
              }

              if (selectedProfile !== undefined) {
                handleClickEditUser(selectedProfile);
              }
            }}
            onRemoveFunction={(items: Array<string>) => {
              for (let i = 0; i < items.length; i++) {
                dispatch(removeProfile(userProfile as Profile, items[i]));
              }
            }}
          />
        ) : (
          <CircularProgress />
        ))}
      <div>
        <ProfileForm
          intl={intl}
          open={open}
          editing={editingProfile !== undefined ? true : false}
          finishCallback={editingProfile === undefined ? handleAddUser : handleEditUser}
          cancelCallback={editingProfile === undefined ? handleCloseNewUser : handleCloseEditUser}
          handleSearchZip={handleSearchZip}
          classeMenuPaper={classes.menuPaper}
          classeMenuPaper2={classes.menuPaper2}
          countriesNationality={countriesNationality}
          role={editingProfile !== undefined ? editingProfile.role : undefined}
          name={editingProfile !== undefined ? editingProfile.name : undefined}
          email={editingProfile !== undefined ? editingProfile.email : undefined}
          cellphoneNumber={
            editingProfile !== undefined ? editingProfile.cellphoneNumber : undefined
          }
          rg={
            editingProfile !== undefined && editingProfile.role !== Role.external
              ? editingProfile.rg
              : undefined
          }
          document={
            editingProfile !== undefined && editingProfile.role !== Role.external
              ? editingProfile.document
              : undefined
          }
          agricultureNumber={
            editingProfile !== undefined && editingProfile.role !== Role.external
              ? editingProfile.agricultureNumber
              : undefined
          }
          enginnerNumber={
            editingProfile !== undefined && editingProfile.role !== Role.external
              ? editingProfile.crea
              : undefined
          }
          aviationNumber={
            editingProfile !== undefined && editingProfile.role !== Role.external
              ? editingProfile.aviationNumber
              : undefined
          }
          landlineNumber={
            editingProfile !== undefined && editingProfile.role !== Role.external
              ? editingProfile.landlineNumber
              : undefined
          }
          country={
            editingProfile !== undefined && editingProfile.role !== Role.external
              ? editingProfile.address.country
              : undefined
          }
          nationality={
            editingProfile !== undefined && editingProfile.role !== Role.external
              ? editingProfile.nationality
              : undefined
          }
          state={
            editingProfile !== undefined && editingProfile.role !== Role.external
              ? editingProfile.address.state
              : undefined
          }
          city={
            editingProfile !== undefined && editingProfile.role !== Role.external
              ? editingProfile.address.city
              : undefined
          }
          street={
            editingProfile !== undefined && editingProfile.role !== Role.external
              ? editingProfile.address.street
              : undefined
          }
          number={
            editingProfile !== undefined && editingProfile.role !== Role.external
              ? editingProfile.address.number
              : undefined
          }
          zipCode={
            editingProfile !== undefined && editingProfile.role !== Role.external
              ? editingProfile.address.zipCode
              : undefined
          }
        />
      </div>
    </div>
  );
}

function rowToStateString(
  role: Role.master | Role.admin | Role.manager | Role.operator | Role.external
): string {
  switch (role) {
    case Role.master:
      return "master";
    case Role.admin:
      return "admin";
    case Role.manager:
      return "manager";
    case Role.operator:
      return "operator";
    case Role.external:
      return "external";
    default:
      return "";
  }
}

interface EditProps {
  intl: IntlShape;
  open: boolean;
  editing: boolean;
  finishCallback: Function;
  cancelCallback: Function;
  handleSearchZip: Function;
  countriesNationality: Country[];
  classeMenuPaper: string;
  classeMenuPaper2: string;
  name?: string;
  email?: string;
  cellphoneNumber?: string;
  rg?: string;
  nationality?: string;
  document?: string;
  agricultureNumber?: string;
  enginnerNumber?: string;
  aviationNumber?: string;
  landlineNumber?: string;
  country?: string;
  state?: string;
  city?: string;
  street?: string;
  number?: string;
  zipCode?: string;
  role?: Role;
}

type ProfileFormState = {
  open: boolean;
  editing: boolean;
  finishCallback: Function;
  cancelCallback: Function;
  name: string;
  email: string;
  cellphoneNumber: string;
  rg: string;
  nationality: string;
  errors: ErrorsForm; // Add all fields in interface for validation.
  document: string;
  agricultureNumber: string;
  enginnerNumber: string;
  aviationNumber: string;
  landlineNumber: string;
  country: string;
  state: string;
  city: string;
  street: string;
  number: string;
  zipCode: string;
  role: string;
};

class ProfileForm extends React.Component<EditProps, ProfileFormState> {
  _extractProps(props: EditProps): any {
    return {
      open: props.open,
      editing: props.editing,
      finishCallback: props.finishCallback,
      cancelCallback: props.cancelCallback,
      name: props.name !== undefined ? props.name : "",
      email: props.email !== undefined ? props.email : "",
      cellphoneNumber: props.cellphoneNumber !== undefined ? props.cellphoneNumber : "",
      rg: props.rg !== undefined ? props.rg : "",
      document: props.document !== undefined ? props.document : "",
      nationality: props.nationality !== undefined ? props.nationality : "BR",
      agricultureNumber: props.agricultureNumber !== undefined ? props.agricultureNumber : "",
      enginnerNumber: props.enginnerNumber !== undefined ? props.enginnerNumber : "",
      aviationNumber: props.aviationNumber !== undefined ? props.aviationNumber : "",
      landlineNumber: props.landlineNumber !== undefined ? props.landlineNumber : "",
      country: props.country !== undefined ? props.country : "",
      errors: {
        landlineNumber: false,
        country: false,
        state: false,
        city: false,
        document: false,
        rg: false,
        street: false,
        number: false,
        name: false,
        email: false,
        zipCode: false,
        cellphoneNumber: false,
      },
      state: props.state !== undefined ? props.state : "",
      city: props.city !== undefined ? props.city : "",
      street: props.street !== undefined ? props.street : "",
      number: props.number !== undefined ? props.number : "",
      zipCode: props.zipCode !== undefined ? props.zipCode : "",
      role: props.role !== undefined ? rowToStateString(props.role) : "operator",
    };
  }

  // All functions that use the form state must be registered with "bind(this)",
  // so that they can use the "setState".
  constructor(props: EditProps) {
    super(props);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFinishCallBack = this.handleFinishCallBack.bind(this);
    this.handleValidInputs = this.handleValidInputs.bind(this);
    this.state = this._extractProps(props);
  }

  componentWillReceiveProps(newProps: EditProps) {
    this.setState(this._extractProps(newProps));
  }

  handleChange(event: React.ChangeEvent<any>) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
      errors: {
        ...this.state.errors,
        [event.target.name]: isValidFormField(event.target.name, event.target.value),
      },
    });
  }

  // Function for search cep, on user exited input ZIPCODE.
  // Search cep in geocoding
  async handleBlur(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (event.currentTarget.name === "zipCode" && event.currentTarget.value !== "") {
      const result: BiohubResponse<GoogleMapsSearchInfo[]> = await this.props.handleSearchZip(
        event.currentTarget.value
      );
      if (result.success && result.data.length > 0) {
        this.setState({
          ...this.state,
          city: result.data[0].city,
          street: result.data[0].address,
          country: result.data[0].country,
          state: result.data[0].state,
        });

        this.handleValidInputs(["city", "street", "country", "state", "number"]);
      }
    }
  }

  // Check if all inputs valids, when user click send form.
  // If the "valuesRequired" is not null, it means that the
  // user only wants to validate some fields.
  handleValidInputs(valuesRequired?: string[]) {
    if (!valuesRequired) {
      valuesRequired = [];

      if (this.state.role !== rowToStateString(Role.external)) {
        for (let key in this.state.errors) {
          valuesRequired.push(key);
        }
      } else {
        valuesRequired.push("cellphoneNumber");
        valuesRequired.push("name");
        valuesRequired.push("email");
      }
    }

    let hasError = false;
    let errors: ErrorsForm = {};
    for (let [key, value] of Object.entries(this.state)) {
      if (valuesRequired.includes(key)) {
        if (!isValidFormField(key, value as string)) {
          hasError = true;
          errors = { ...errors, [key]: true };
        }
      }
    }

    this.setState({
      errors: errors,
    });

    return hasError;
  }

  handleFinishCallBack() {
    const role = getRole(this.state.role);
    const baseEveryUserFields = ["name", "email", "cellphoneNumber"];
    const baseManagerAndOperatorValidationFields = [
      ...baseEveryUserFields,
      "landlineNumber",
      "nationality",
      "document",
      "zipCode",
      "street",
      "number",
      "state",
      "country",
    ];
    if (
      this.handleValidInputs(
        role === Role.operator || role === Role.manager
          ? this.state.nationality === "BR"
            ? [...baseManagerAndOperatorValidationFields, "rg"]
            : baseManagerAndOperatorValidationFields
          : baseEveryUserFields
      )
    ) {
      this.state.finishCallback(this.state);
    }
  }

  //console.log(getCountries(locale));

  render() {
    const intl = this.props.intl;
    return (
      <Modal
        open={this.state.open}
        onClose={(e) => this.state.editing}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {this.state.editing
            ? intl.formatMessage({ id: "info.profile.edit" })
            : intl.formatMessage({ id: "info.profile.add" })}
          <Icon onClick={(e) => this.state.cancelCallback()}>close</Icon>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.editing
              ? intl.formatMessage({ id: "info.profile.edit.message" })
              : intl.formatMessage({ id: "info.profile.add.message" })}
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item lg={4} xs={5}>
              <b>{intl.formatMessage({ id: "info.profile.userType" }) + ": "}</b>
            </Grid>
            <Grid item lg={8} xs={7}>
              <DropdownOptions<Role>
                baseId="profile-role"
                label={intl.formatMessage({ id: "info.profile.userType" })}
                options={[Role.manager, Role.operator, Role.external]}
                getOptionName={(role) => {
                  if (role === Role.manager) {
                    return intl.formatMessage({ id: "info.profile.userType.manager" });
                  }
                  if (role === Role.operator) {
                    return intl.formatMessage({ id: "info.profile.userType.operator" });
                  }
                  if (role === Role.external) {
                    return intl.formatMessage({ id: "info.profile.userType.external" });
                  }
                  return "";
                }}
                value={getRole(this.state.role) ?? undefined}
                onChange={(role) => {
                  this.setState({ role: role === undefined ? "" : rowToStateString(role) });
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <b>{intl.formatMessage({ id: "info.profile.personalInformations" })} </b>
            <Grid item lg={12} xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label={intl.formatMessage({ id: "placeholder.name" }) + "*"}
                name="name"
                fullWidth
                variant="outlined"
                value={this.state.name}
                onChange={this.handleChange}
                error={this.state.errors.name}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <TextField
                disabled={this.props.editing}
                margin="dense"
                id="email"
                label={intl.formatMessage({ id: "placeholder.email" }) + "*"}
                fullWidth
                name="email"
                variant="outlined"
                value={this.state.email}
                onChange={this.handleChange}
                error={this.state.errors.email}
              />
            </Grid>
            {/* TODO: Check cpf, cpnj and iva for other countries */}
            <InsertPhone
              fieldId="cellphoneNumber"
              labelId="languageCode-label"
              label={intl.formatMessage({ id: "placeholder.cellphone" }) + "*"}
              error={this.state.errors.cellphoneNumber}
              onChange={this.handleChange}
              paperClass={this.props.classeMenuPaper}
              phone={this.state.cellphoneNumber}
            />
            {getRole(this.state.role) !== Role.external && [
              <InsertPhone
                fieldId="landlineNumber"
                labelId="languageCode2-label"
                label={intl.formatMessage({ id: "placeholder.phone" }) + "*"}
                error={this.state.errors.landlineNumber}
                onChange={this.handleChange}
                paperClass={this.props.classeMenuPaper2}
                phone={this.state.landlineNumber}
              />,
            ]}

            {getRole(this.state.role) !== Role.external && [
              <Grid item lg={6} xs={6} style={{ paddingTop: 12 }}>
                <Autocomplete<Country>
                  id="nationality"
                  componentName="nationality"
                  fullWidth
                  options={this.props.countriesNationality}
                  size="small"
                  value={this.props.countriesNationality.find(
                    (c) => c.alpha2Code === this.state.nationality
                  )}
                  autoHighlight
                  onChange={(e, newValue) => {
                    if (newValue !== null)
                      this.handleChange({
                        ...e,
                        target: { ...e.target, name: "nationality", value: newValue.alpha2Code },
                      });
                  }}
                  getOptionLabel={(option) => option.countryName}
                  renderOption={(option) => option.countryName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={intl.formatMessage({ id: "info.profile.nationality" })}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>,
              <Grid item lg={6} xs={6}>
                <TextField
                  margin="dense"
                  id="document"
                  label={this.state.nationality === "BR" ? "CPF/CNPJ*" : "ID*"}
                  error={this.state.errors.document}
                  fullWidth
                  name="document"
                  variant="outlined"
                  value={this.state.document}
                  onChange={this.handleChange}
                />
              </Grid>,
              <Grid item lg={6} xs={6}>
                <TextField
                  margin="dense"
                  id="agriculture-number"
                  label={intl.formatMessage({ id: "info.profile.agricultureNumber" })}
                  fullWidth
                  name="agricultureNumber"
                  variant="outlined"
                  value={this.state.agricultureNumber}
                  onChange={this.handleChange}
                />
              </Grid>,
              <Grid item lg={6} xs={6}>
                <TextField
                  margin="dense"
                  id="crea"
                  label={intl.formatMessage({ id: "info.profile.crea" })}
                  fullWidth
                  name="enginnerNumber"
                  variant="outlined"
                  value={this.state.enginnerNumber}
                  onChange={this.handleChange}
                />
              </Grid>,
              <Grid item lg={6} xs={6}>
                <TextField
                  margin="dense"
                  id="ava"
                  label={intl.formatMessage({ id: "info.profile.ava" })}
                  fullWidth
                  name="aviationNumber"
                  variant="outlined"
                  value={this.state.aviationNumber}
                  onChange={this.handleChange}
                />
              </Grid>,
              this.state.nationality === "BR" && (
                <Grid item lg={6} xs={6}>
                  <TextField
                    margin="dense"
                    id="rg"
                    label="RG*"
                    fullWidth
                    error={this.state.errors.rg}
                    name="rg"
                    variant="outlined"
                    value={this.state.rg}
                    onChange={this.handleChange}
                  />
                </Grid>
              ),
            ]}
            {getRole(this.state.role) !== Role.external && (
              <>
                <Grid item lg={12} xs={12}>
                  <b>{intl.formatMessage({ id: "placeholder.address" }) + ": "}</b>
                </Grid>
                <Grid item lg={5} xs={6}>
                  <TextField
                    margin="dense"
                    id="zipcode"
                    label={intl.formatMessage({ id: "placeholder.zip" }) + "*"}
                    fullWidth
                    name="zipCode"
                    variant="outlined"
                    value={this.state.zipCode}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    error={this.state.errors.zipCode}
                  />
                </Grid>
                <Grid item lg={7} xs={6}>
                  <TextField
                    margin="dense"
                    id="country"
                    label={intl.formatMessage({ id: "placeholder.country" }) + "*"}
                    fullWidth
                    name="country"
                    variant="outlined"
                    value={this.state.country}
                    onChange={this.handleChange}
                    error={this.state.errors.country}
                  />
                </Grid>
                <Grid item lg={7} xs={7}>
                  <TextField
                    margin="dense"
                    id="state"
                    label={intl.formatMessage({ id: "placeholder.state.department" }) + "*"}
                    fullWidth
                    name="state"
                    variant="outlined"
                    value={this.state.state}
                    onChange={this.handleChange}
                    error={this.state.errors.state}
                  />
                </Grid>
                <Grid item lg={5} xs={5}>
                  <TextField
                    margin="dense"
                    id="city"
                    label={intl.formatMessage({ id: "placeholder.city" }) + "*"}
                    fullWidth
                    name="city"
                    variant="outlined"
                    value={this.state.city}
                    onChange={this.handleChange}
                    error={this.state.errors.city}
                  />
                </Grid>
                <Grid item lg={9} xs={8}>
                  <TextField
                    margin="dense"
                    id="street"
                    label={intl.formatMessage({ id: "placeholder.street" }) + "*"}
                    fullWidth
                    name="street"
                    variant="outlined"
                    value={this.state.street}
                    onChange={this.handleChange}
                    error={this.state.errors.street}
                  />
                </Grid>
                <Grid item lg={3} xs={4}>
                  <TextField
                    margin="dense"
                    id="number"
                    label={intl.formatMessage({ id: "placeholder.number" }) + "*"}
                    fullWidth
                    name="number"
                    variant="outlined"
                    value={this.state.number}
                    onChange={this.handleChange}
                    error={this.state.errors.number}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => this.state.cancelCallback()}
            className="cancel-button"
            startIcon={<Icon>close</Icon>}
          >
            {intl.formatMessage({ id: "generic.cancel" })}
          </Button>
          <Button onClick={this.handleFinishCallBack} variant="contained" color="primary">
            {this.state.editing
              ? intl.formatMessage({ id: "action.save" })
              : intl.formatMessage({ id: "action.add" })}
          </Button>
        </DialogActions>
      </Modal>
    );
  }
}
