import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Switch,
  useParams,
} from "react-router-dom";

import LandingPage from "../pages/LandingPage";
import AboutPage from "../pages/LandingPage/containers/AboutPage";
import ChangePasswordPage from "../pages/LandingPage/containers/ChangePasswordPage";
import ContactPage from "../pages/LandingPage/containers/ContactPage";
import PricesPage from "../pages/LandingPage/containers/PricesPage";
import PrivacyPage from "../pages/LandingPage/containers/PrivacyPage";
import SignPage from "../pages/LandingPage/containers/SignPage";
import SolutionPage from "../pages/LandingPage/containers/SolutionsPage";
import SupportPage from "../pages/LandingPage/containers/SupportPage";
import TermsPage from "../pages/LandingPage/containers/TermsPage";
import Product from "../pages/Product";
// import ProductPage from "../pages/LandingPage/containers/ProductsPage";
import PurchaseOrderIntentPage from "../pages/LandingPage/containers/PurchaseOrderIntentPage";
import VerificationPage from "../pages/LandingPage/containers/VerificationPage";
import Profile from "../pages/Profile";
import FlightReport from "../pages/Reports/FlightReport";
import MapReport from "../pages/Reports/containers/MapReport";
import Teaser from "../pages/Teaser";
import Dashboard from "../pages/dashboard";
import Login from "../pages/login";
import MapPage from "../pages/map/MapPage";
import SetupAccountScreen from "../pages/setupAccount";

import { useLoadScript } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { useIntl } from "react-intl";
import Constants from "../Constants";
import CartPage from "../pages/LandingPage/containers/CartPage";
import { BiohubLocale } from "../store/reducers/localeReducer";
import { SystemState } from "../store/reducers/systemReducer";

import StateErrorMessage from "./PrivatePagesStateErrorMessage";
import { StaticContext } from "react-router";
import { fetchCollections } from "../store/actions/collectionsActions";
import { clearData, getCacheDataService } from "../services/Persistence/CacheDataService";
import { KEEP_DATA_PERSIST, LOG_IN_SUCCESS, LOG_OUT } from "../store/actions/loginActions";
import { loadProfileInformation } from "../store/actions/profileActions";
import { CircularProgress } from "@material-ui/core";
import Image from "../components/Atomic/BasicComponents/Image";
import logo from "../assets/logo/logo_blue.svg";

const _LIBRARIES: Libraries = ["places"];

type CreateRenderChildren =
  | {
      render?: undefined;
      children: JSX.Element;
    }
  | {
      render: (
        props: RouteComponentProps<
          {
            [x: string]: string | undefined;
          },
          StaticContext,
          unknown
        >
      ) => React.ReactNode;
      children?: undefined;
    };

const PrivateAreaComponent = (props: { children: JSX.Element }) => {
  const userProfile = useSelector((state: SystemState) => state.profile.userProfile);
  const [loadingProfile, setLoadingProfile] = useState(true);

  const localeCode = useSelector((state: SystemState) => state.locale.localeCode);
  const localeChanged = useSelector((state: SystemState) => state.locale.changed);

  const dispatch = useDispatch();

  const loadAuthenticatedProfile = async () => {
    const cacheDataService = getCacheDataService();

    const loginInfo = await cacheDataService?.retrieveLoginInfo();

    if (loginInfo === undefined) {
      await clearData();

      dispatch({
        type: LOG_OUT,
      });

      return;
    }

    /// Put back in the state the login variables
    dispatch({
      type: LOG_IN_SUCCESS,
      payload: {
        userToken: loginInfo.token,
        userId: loginInfo.userId,
      },
    });

    /// Put back in the state the keep logged in variable
    dispatch({
      type: KEEP_DATA_PERSIST,
      payload: {
        status: loginInfo.keepLoggedIn,
      },
    });

    dispatch(
      loadProfileInformation(loginInfo.userId, localeCode, localeChanged, () =>
        dispatch(fetchCollections())
      )
    );
  };

  useEffect(() => {
    if (userProfile === null) {
      loadAuthenticatedProfile();
    }
  }, []);

  useEffect(() => {
    if (loadingProfile && userProfile !== null) {
      setLoadingProfile(false);
    }
  }, [userProfile]);

  if (loadingProfile) {
    return (
      <div
        style={{
          height: window.innerHeight,
          width: window.innerWidth,
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            msTransform: "translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image src={logo} appearance="img-divulgation-banner" />
          <CircularProgress
            style={{
              marginTop: -50,
            }}
          />
        </div>
      </div>
    );
  }

  return props.children;
};

const CreateRoute = (
  props: {
    exact?: boolean;
    path: string;
    publicArea: boolean;
  } & CreateRenderChildren
): JSX.Element => {
  if (!props.publicArea) {
    return (
      <PrivateAreaComponent>
        <PrivateRoute
          path={props.path}
          exact={props.exact}
          render={
            props.render !== undefined
              ? (routeProps) => <StateErrorMessage>{props.render(routeProps)}</StateErrorMessage>
              : undefined
          }
        >
          {props.children !== undefined && <StateErrorMessage>{props.children}</StateErrorMessage>}
        </PrivateRoute>
      </PrivateAreaComponent>
    );
  }

  return (
    <Route
      path={props.path}
      exact={props.exact}
      render={
        props.render !== undefined
          ? (routeProps) => <StateErrorMessage>{props.render(routeProps)}</StateErrorMessage>
          : undefined
      }
    >
      {props.children !== undefined && <StateErrorMessage>{props.children}</StateErrorMessage>}
    </Route>
  );
};

const Routes = () => (
  // <BrowserRouter>
  <Switch>
    <CreateRoute path="/readiness_check" publicArea={true}>
      <h3>App is ready</h3>
    </CreateRoute>
    <CreateRoute path="/liveness_check" publicArea={true}>
      <h3>App is live</h3>
    </CreateRoute>
    <CreateRoute exact path="/teaser" publicArea={true}>
      <Teaser />
    </CreateRoute>

    {/* Website  */}
    <CreateRoute exact path="/privacy" publicArea={true}>
      <PrivacyPage />
    </CreateRoute>

    <CreateRoute exact path="/terms" publicArea={true}>
      <TermsPage />
    </CreateRoute>
    <CreateRoute exact path="/support" publicArea={true}>
      <SupportPage />
    </CreateRoute>
    <CreateRoute exact path="/about" publicArea={true}>
      <AboutPage />
    </CreateRoute>
    <CreateRoute exact path="/solutions" publicArea={true}>
      <SolutionPage />
    </CreateRoute>
    {/*Product Page Public*/}
    <CreateRoute
      exact
      publicArea={true}
      path="/product/:name"
      render={(props) => (
        <StateErrorMessage>
          <Product key={props.match.params.name} />
        </StateErrorMessage>
      )}
    />
    {/*Product Page Public End*/}

    <CreateRoute exact path="/prices" publicArea={true}>
      <PricesPage />
    </CreateRoute>
    <CreateRoute exact path="/contact" publicArea={true}>
      <ContactPage />
    </CreateRoute>
    <CreateRoute exact path="/purchase_order_intent" publicArea={true}>
      <PurchaseOrderIntentPage />
    </CreateRoute>
    <CreateRoute exact path="/cart" publicArea={true}>
      <CartPage />
    </CreateRoute>
    <CreateRoute exact path="/signUp" publicArea={true}>
      <SignPage />
    </CreateRoute>
    {/* Temporary URL */}
    <CreateRoute exact path="/changepassword" publicArea={true}>
      <ChangePasswordPage />
    </CreateRoute>
    <CreateRoute exact path="/verification" publicArea={true}>
      <VerificationPage />
    </CreateRoute>
    <CreateRoute exact path="/setupaccount" publicArea={true}>
      <SetupAccountScreen />
    </CreateRoute>
    {/* Landing */}
    <CreateRoute exact path="/" publicArea={true}>
      <LandingPage />
    </CreateRoute>
    <CreateRoute exact path="/landingpage" publicArea={true}>
      <LandingPage />
    </CreateRoute>
    <CreateRoute exact path="/login" publicArea={true}>
      <Login />
    </CreateRoute>
    {/* Report Items */}
    <CreateRoute exact path="/reports/flight" publicArea={false}>
      <FlightReport />
    </CreateRoute>
    <CreateRoute exact path="/reports/map" publicArea={false}>
      <MapReport />
    </CreateRoute>
    {/* // TODO:  this is pending or can be deleted? */}
    {/* What is this? ->   <Route exact path="/contrato" component={ContratoPdf}></Route> */}
    {/** For anyone with time, please take a look at this page. */}
    {/** I don't know how it renders (or _if_ it renders), since ContratoPdf isn't a component */}
    {/** The following pages require authentication (will redirect to /login if not authenticated) */}
    <CreateRoute path="/dashboard" publicArea={false}>
      <Dashboard />
    </CreateRoute>
    <CreateRoute exact path="/map" publicArea={false}>
      <MapPage />
    </CreateRoute>
    {/**TODO: Fix that problem to access the private route profile */}
    <CreateRoute path="/profile" publicArea={false}>
      <Profile />
    </CreateRoute>
    {/* <PrivateRoute path="/profile/settings">
      <Settings />
    </PrivateRoute> */}
  </Switch>
);

export default Routes;

/**
 *  Private routes that will redirect to /login if accessed without authentication.
 * */
function PrivateRoute(props: { children: React.ReactNode } & RouteProps): JSX.Element {
  const isGettingUserInformation = useSelector(
    (state: SystemState) => state.login.isGettingUserInformation
  );
  const authenticated = useSelector((state: SystemState) => state.login.userToken !== null);
  const locale: BiohubLocale = useSelector((state: SystemState) => state.locale.localeCode);

  // `children` is a `React.ReactNode`, `...rest` is all the rest (`RouteProps` without `children`).
  // It's important that `...rest` does not have `children`, because then `render` would be ignored.
  const { children, ...rest } = props;

  const intl = useIntl();
  const loadScript = useLoadScript({
    region: intl.formatMessage({ id: "map.defaultRegion" }),
    language: locale,
    googleMapsApiKey: Constants.GOOGLE_MAPS_API_KEY,
    libraries: _LIBRARIES,
  });

  // This is just a log, the real logic is up ahead.
  if (!authenticated) {
    console.log("Not authenticated, will redirect to login.");
  }

  // https://reactrouter.com/web/example/auth-workflow
  return (
    <Route
      {...rest} // <- Pass all props that are passed to this component, *except* children.
      render={({ location }) =>
        // If the user is authenticated, proceed to render the children passed to this component.
        !authenticated && !isGettingUserInformation ? (
          // Otherwise, redirect to /login setting this page as the location's "from".
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location.pathname },
            }}
          />
        ) : (
          props.children
        )
      }
    />
  );
}
