import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Link from "../../components/Atomic/BasicComponents/Link";
import Container from "../../components/Atomic/Areas/Container";
import Row from "../../components/Atomic/Areas/Row";
import FormLogin from "../../components/Atomic/Forms/Login";
import Flags from "../../components/Atomic/Tools/FlagLocalePicker";
import { LoginPage, Actions } from "./styles";

// Images
import imageBackground from "../../assets/background/principal.jpg";
import { Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../store/reducers/systemReducer";
import { getCacheDataService } from "../../services/Persistence/CacheDataService";
import { KEEP_DATA_PERSIST, LOG_IN_SUCCESS } from "../../store/actions/loginActions";

interface Props {
  imageBackground?: string | "url";
}

export default (props: Props) => {
  const intl = useIntl();

  const stateLoggedIn = useSelector((state: SystemState) => state.login.userId !== null);

  const [loggedIn, setLoggedIn] = useState(false);

  const dispatch = useDispatch();

  const retrieveLoginInfo = async () => {
    const loginInfo = await getCacheDataService()?.retrieveLoginInfo();

    if (loginInfo === undefined) return;

    /// Put back in the state the login variables
    dispatch({
      type: LOG_IN_SUCCESS,
      payload: {
        userToken: loginInfo.token,
        userId: loginInfo.userId,
      },
    });

    /// Put back in the state the keep logged in variable
    dispatch({
      type: KEEP_DATA_PERSIST,
      payload: {
        status: loginInfo.keepLoggedIn,
      },
    });

    setLoggedIn(true);
  };

  useEffect(() => {
    if (stateLoggedIn) return;

    retrieveLoginInfo();
  }, []);

  if (stateLoggedIn || loggedIn) {
    return (
      <Redirect
        to={{
          pathname: "/dashboard",
        }}
      />
    );
  }
  return (
    <>
      <LoginPage background={imageBackground}>
        <Container>
          <Actions>
            <Link to="/" appearance={"button-outline"} color={"light"} size={"medium"}>
              {intl.formatMessage({ id: "action.back" })}
            </Link>
          </Actions>
          <Row alignItems={"center"}>
            <FormLogin />
          </Row>
        </Container>
        <Flags />
      </LoginPage>
    </>
  );
};
