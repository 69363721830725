export const releaseNotesText = `
## [GMT-3 12/02/2025 16:29]

- Fix problems in the first redirecting to private area after login and not getting the necessary profile info

## [GMT-3 28/01/2025 15:54]

- Fix correction factor usage and release estimation when we have releaser actions to enable and disable releasing the planned route

## [GMT-3 27/01/2025 11:40]

- Create release rate correction factor in the area settings to use a corrected release rate value to release the amount of the expected input in the area avoid difference because the flight path doesn't cover 100% of the area

## [GMT-3 16/12/2024 16:57]

- Dashboard graphs and info changes. Now we get using an optimized endpoint. The graph separate info daily. In the backend we remove not necessary tables and concentrate the information in only one place

## [GMT-3 12/12/2024 11:56]

- Fix failure restoring login infinite loop

## [GMT-3 29/11/2024 10:11]

- Improve flight layers transparency improving visualization of the planned area

## [GMT-3 28/11/2024 15:54]

- Fix map error because of Google Maps Tiles API quota
- Fix 100% without map images

## [GMT-3 27/11/2024 16:21]

- Deactivate view project and areas marked as deleted

## [GMT-3 26/11/2024 15:28]

- Fix error reopening (going to another page and coming back) the map (bioMAPS page)
- Improve map quality
- Improve map labels on hybrid mode
- Add internationalization on the map by using the Google Maps Tiles API providing language and region codes

## [GMT-3 18/11/2024 10:14]

- Put waypoints between 250 meters if the option to consider relief is on to have a more accurate online elevation map follow on the mission

## [GMT-3 24/10/2024 10:44]

- Improve map search places by changing the color
- Change map copyrights to be more similar than others platforms maps
- Map scale rule using dynamic size to always present the scale size in metric in an integer value

## [GMT-3 22/10/2024 11:35]

- Fix not showing waypoint online elevation relative height if the area will not using online elevation

## [GMT-3 21/10/2024 12:02]

- Climatic info on flight report. Old flights will not have values, but as every flight you can edit and put your data
- Fix mouse click in the ending of the project tree list

## [GMT-3 17/10/2024 15:34]

- Show waypoint latitude and longitude on waypoint modal
- Show waypoint elevation diff from the home point (X above the home point) on waypoint modal
- Show home point modal when click in the home point. That modal show the latitude, longitude and the altitude
- Remove home point altitude from the map
- Show only effective waypoint height on the map. The previous implementation was showing two lines, the first one was the absolute height from the ground and the other one the, if different than that (if we have an height diff from the home point), the effective height considering the home point altitude

## [GMT-3 11/10/2024 16:43]

- Fix disappearing areas when update project

## [GMT-3 10/10/2024 14:58]

- Control map planned area, flight and release tracks layers showing elements in the following order: area polygon, area planned route, flight release segments (green segments) and flight route

## [GMT-3 10/10/2024 09:09]

- Map distance and height values with 2 decimal places like bioMAPS
- Fix on flight report: Planned flight date and hour; Real flight date and hour
- Release estimation on opened area info overlay in the map
- Opened area info overlay showing linear distance of planned route instead of the previous implementation which showed area perimeter

## [GMT-3 02/10/2024 12:10]

- Limit area size in creation to 10 thousand hectares (if it is a square the size would be 10 km by 10 km).
- On select project in project tree go to the bounding box of all project areas together.
- Better map zoom control using percentage.
- Initial map type to satellite
- Restore scroll position in the project tree when reopen

## [GMT-3 27/8/2024 12:09]

- Change data persistence to avoid problems. That change will affect the login, so everyone needs to login again in the website.

- Code fix in the deleted or not deleted projects in the project tree.

- Implementation to fix problem deleting area and other project areas disappearing

- Feature to change if you are seeing not deleted (default option), deleted and all projects and areas in the project tree. You can also mark and option if you want to see and item because a subitem with deleted or not according to the actual view mode. To control that we have a settings button in the project tree ![Project tree control view mode](/release_notes/project_tree_view_modes.png)

## [GMT-3 20/8/2024 09:26]

- New mission planner implementation. After that every new area or new mission planner execution will use the new algorithm. To execute that in a already planned area you can select the "Generate new area route" option in the opened area modal actions like that:
  ![New mission planner generate new area route](/release_notes/new_mission_planner_generate_new_area_route.png)

- New area preferences to control if is an option or not an route outside the polygon to connect different parts and try to make a more efficient flight without a lot of curves. You can see that preference in the project / area settings, or in your profile preferences. You can also see an example of a planned area using that flag enabled
  ![New mission planner can use outside segments in route](/release_notes/new_mission_planner_can_use_outside_segments_in_route.png)

- That new mission planner also improved the home point location when we are using the highest point in the border of the polygon as home point

- New feature to rotate the mission plan
  ![New mission planner rotate mission plan](/release_notes/new_mission_plann_rotate_plan.png)

## [GMT-3 19/8/2024 13:44]

- RELEASE NOTES BEGIN


`;
